// ga4Utilities.js

const GA_MEASUREMENT_ID = "G-9LKWCSQBG6";
let isConfigured = false; // Flag to track if GA4 has already been configured

/**
 * Configure GA4 with user-specific settings, such as user_id.
 * This function ensures `configureGA` runs only once per session.
 * @param {string} userId - The unique user ID to associate with the session.
 */

export const configureGA = (userId) => {
  if (isConfigured) {
    console.warn("GA4 is already configured for this session.");
    return;
  }

  if (window.gtag) {
    // Configure GA4 with the user ID
    window.gtag("config", GA_MEASUREMENT_ID, {
      user_id: userId,
    });

    // Track a custom event to signify authorization
    window.gtag("event", "user_authorized", {
    });

    isConfigured = true; // Mark as configured
  } else {
    console.warn("Google Analytics is not initialized.");
  }
};

/**
 * Track custom events in GA4.
 * @param {string} eventName - The name of the event to track.
 * @param {Object} params - Additional parameters to include with the event.
 */
export const trackEvent = (eventName, params = {}) => {
  if (window.gtag) {
    window.gtag("event", eventName, params);
  } else {
    console.warn("Google Analytics is not initialized.");
  }
};

/**
 * Clear the user_id to disassociate it from future events (e.g., during logout).
 */
export const clearUserId = () => {
  if (window.gtag) {
    window.gtag("config", GA_MEASUREMENT_ID, {
      user_id: null,
    });
    window.gtag("event", "user_logout", {
    });

    isConfigured = false; // Reset the configuration flag
  } else {
    console.warn("Google Analytics is not initialized.");
  }
};
