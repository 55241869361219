import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

const Counters = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 10px;
  flex-wrap: no-wrap;
  font-size: 21px;
  margin: 15px 0px;

  span {
    letter-spacing: 1px;
    display: flex;
    background: #f1f1f1;
    border-radius: 3px;
    font-family: DIN Alternate;
    height: 50px;
    box-sizing: border-box;
    width: fit-content;
    align-items: center;
    padding: 30px 20px;
  }
  @media screen and (max-width: 1200px) {
    letter-spacing: 0px;
    font-size: 21px;
    gap: 5px;
    justify-content: flex-start;
    span {
      padding: 30px 15px;
    }
  }
  @media screen and (max-width: 1024px) {
    gap: 5px;
    font-size: 18px;
    span {
      padding: 20px 15px;
    }
  }

  @media screen and (max-width: 640px) {
    font-size: 14px;
    gap: 3px;
    height: 40px;
    margin-bottom: 20px;
    span {
      height: 50px;
      padding: 10px 13px;
    }
  }
  @media screen and (max-width: 430px) {
    span {
    }
  }
  @media screen and (max-width: 335px) {
    span {
    }
  }
`;

const targetTime = new Date("2025-03-31").getTime();

export const Counter = () => {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const timeBetween = targetTime - currentTime;
  const totalSeconds = Math.floor(timeBetween / 1000);

  const totalDays = Math.floor(totalSeconds / (60 * 60 * 24));
  const months = Math.floor(totalDays / 30);
  const weeks = Math.floor((totalDays % 30) / 7);
  const days = totalDays % 7;
  const hours = Math.floor((totalSeconds / (60 * 60)) % 24);
  const minutes = Math.floor((totalSeconds / 60) % 60);
  const seconds = String(totalSeconds % 60).padStart(2, "0");

  return (
    <Counters>
      {months > 0 && <span>{months}m</span>}
      {weeks > 0 && <span>{weeks}w</span>}
      {days > 0 && <span>{days}d</span>}
      {hours > 0 && <span>{hours}h</span>}
      {minutes > 0 && <span>{minutes}m</span>}
      <span>{seconds}s !</span>
    </Counters>
  );
};
