import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "App"; // adjust the path as needed
import AlphaFlight from "pages/AlphaFlight";
import Dashboard from "pages/Dashboard";
import NotFound from "utilities/404";
import { ProtectedContent, UseCheckStatusRoute } from "utilities/state/routeHooks";
import HomePage from "website/home";
import LoginContainer from "pages/login/login";
import HomeSignup from "website/homesignup";
import { ProfileForm } from "website/homeprofile";
import { useState, useMemo, useEffect } from "react";
import { supabase } from "supabaseClient";
import { Navigate } from "../node_modules/react-router-dom/dist/index";
import HomeTitle from "website/hometitle";
import HomeBlogRoll from "website/homeblogroll";
import { useContext } from "react";
import AppContext from "AppContext";
import BlogPost from "website/homeblog";

function Roots() {
  const [sessionExists, setSessionExists] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const selectedPost = useContext(AppContext);

  // route hooks
  function RedirectRoute() {
    return <Navigate to="/" replace />;
  }

  useEffect(() => {
    setLoading(true); // Set loading when checking auth state
    const { data: authListener, error } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSessionExists(!!session);
        setLoading(false); // Set loading to false after check
      }
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  return (
    <BrowserRouter>
      <App>
        <Routes>
          <Route
            path="/"
            element={
              <HomePage
                leftTitle={<HomeTitle />}
                pageName="home"
                pageForm={sessionExists ? <ProfileForm /> : <HomeSignup />}
              />
            }
          />
          <Route
            path="/organizations"
            element={
              <HomePage
                leftTitle={<HomeTitle />}
                pageName="organizations"
                pageForm={sessionExists ? <ProfileForm /> : <HomeSignup />}
              />
            }
          />
          <Route
            path="/community"
            element={
              <HomePage
                pageName="community"
                leftTitle={<HomeTitle />}
                pageForm={sessionExists ? <ProfileForm /> : <HomeSignup />}
              />
            }
          />
          <Route
            path="/founders"
            element={
              <HomePage
                pageName="startups"
                leftTitle={<HomeTitle />}
                pageForm={sessionExists ? <ProfileForm /> : <HomeSignup />}
              />
            }
          />
          <Route
            path="/ecosystems"
            element={
              <HomePage
                pageName="ecosystems"
                leftTitle={<HomeTitle />}
                pageForm={sessionExists ? <ProfileForm /> : <HomeSignup />}
              />
            }
          />
          <Route
            path="/service-providers"
            element={
              <HomePage
                pageName="service providers"
                leftTitle={<HomeTitle />}
                pageForm={sessionExists ? <ProfileForm /> : <HomeSignup />}
              />
            }
          />
          <Route
            path="/profile"
            element={
              loading ? (
                <HomePage
                  pageName="profile"
                  leftTitle={<HomeTitle />}
                  pageForm={<ProfileForm />}
                />
              ) : sessionExists ? (
                <HomePage
                  pageName="profile"
                  leftTitle={<HomeTitle />}
                  pageForm={<ProfileForm />}
                />
              ) : (
                <RedirectRoute />
              )
            }
          />

          {/* Blog */}

          <Route
            path="/canadianwomensfoundation"
            element={<Navigate to="/org/canadianwomensfoundation" replace />}
          />
          <Route
            path="/womenfounders"
            element={<Navigate to="/org/canadianwomensfoundation" replace />}
          />
          <Route
            path="/org/canadianwomensfoundation"
            element={
              <HomePage
                pageName="canadianwomensfoundation"
                leftTitle={<HomeTitle />}
                pageForm={sessionExists ? <ProfileForm /> : <HomeSignup />}
              />
            }
          />
          <Route
            path="/thrivelab"
            element={<Navigate to="/org/thrivelab" replace />}
          />
          <Route
            path="/bdc"
            element={<Navigate to="/org/thrivelab" replace />}
          />
          <Route
            path="/org/thrivelab"
            element={
              <HomePage
                pageName="thrivelab"
                leftTitle={<HomeTitle />}
                pageForm={sessionExists ? <ProfileForm /> : <HomeSignup />}
              />
            }
          />
          <Route
            path="/parkdale"
            element={<Navigate to="/org/parkdale" replace />}
          />
          <Route
            path="/parkdaleinnovates"
            element={<Navigate to="/org/parkdale" replace />}
          />
          <Route
            path="/org/parkdale"
            element={
              <HomePage
                pageName="parkdaleinnovates"
                leftTitle={<HomeTitle />}
                pageForm={sessionExists ? <ProfileForm /> : <HomeSignup />}
              />
            }
          />

          <Route
            path="/news/:slug"
            element={
              <HomePage
                pageName="news"
                leftTitle={<HomeBlogRoll />}
                pageForm={<BlogPost />}
              />
            }
          />

          <Route
            path="/news"
            element={
              <HomePage
                leftTitle={<HomeBlogRoll />}
                pageName="news"
                pageForm={<BlogPost />}
              />
            }
          />
          <Route
            path="/terms"
            element={
              <HomePage
                leftTitle={<HomeBlogRoll />}
                pageName="terms"
                pageForm={<BlogPost />}
              />
            }
          />
          <Route
            path="/privacy"
            element={
              <HomePage
                leftTitle={<HomeBlogRoll />}
                pageName="privacy"
                pageForm={<BlogPost />}
              />
            }
          />
          <Route
            path="/guidelines"
            element={
              <HomePage
                leftTitle={<HomeBlogRoll />}
                pageName="guidelines"
                pageForm={<BlogPost />}
              />
            }
          />
          <Route
            path="/indigenous-relations"
            element={
              <HomePage
                leftTitle={<HomeBlogRoll />}
                pageName="news"
                pageForm={<BlogPost />}
              />
            }
          />
          <Route
            path="/investor-relations"
            element={
              <HomePage
                leftTitle={<HomeBlogRoll />}
                pageName="investor-relations"
                pageForm={<BlogPost />}
              />
            }
          />
          <Route
            path="/copyright"
            element={
              <HomePage
                leftTitle={<HomeBlogRoll />}
                pageName="copyright"
                pageForm={<BlogPost />}
              />
            }
          />
          <Route path="login" element={<LoginContainer />} />
          {/* check if the environment variable is set to development */}
          {process.env.NODE_ENV === "development" && (
            <>
              <Route
                path="dashboard"
                element={
                  <UseCheckStatusRoute>
                    <Dashboard />
                  </UseCheckStatusRoute>
                }
              />
              <Route
                path="alphaflight"
                element={
                  <ProtectedContent>
                    <AlphaFlight />
                  </ProtectedContent>
                }
              />
            </>
          )}
  
          <Route
            path="*"
            element={
              <ProtectedContent>
                <NotFound />
              </ProtectedContent>
            }
          />
        </Routes>
      </App>
    </BrowserRouter>
  );
}

export default Roots;
