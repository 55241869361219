import React from "react";
import FormControl from "@mui/material/FormControl";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { provinces } from "./siteutilities";
import { NavItems, Orgs } from "./navutilities";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { trackEvent } from "website/ga4utilities";

//  Checkboxes and Radio Buttons
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import ListSubheader from "@mui/material/ListSubheader";
import FormHelperText from "@mui/material/FormHelperText";

import { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { supabase } from "supabaseClient";
import AppContext from "AppContext";

const ProfileSignUp = styled(FormControl)`
  display: flex;
  flex-direction: column;
  align-items: left;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 1px #f1f1f1;
  text-align: left;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 10px 2px rgba(212, 212, 212, 0.24);
  box-sizing: border-box;
  border-radius: 5px 5px 0px 0px;
  position: relative;
  height: 100%;
  overflow: hidden;
  @media screen and (max-width: 640px) {
    overflow-y: scroll;
  }
  h1 {
    font-size: 21px;
    margin: 8px 0px 25px;
  }
  h4 {
    padding: 30px 0px 3px;
    margin: 0px 40px 0px 30px;
  }
  h5 {
    margin: 0px;
    margin-top: 10px;
  }
  .border {
    height: 2px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0px 0px 10px 2px rgba(212, 212, 212, 0.24);
    background: #f9f9f9;
  }

  &:nth-child(even) {
  }
  form {
    // max-height: 400px;
    overflow-y: scroll;
    margin-top: 25px;
    padding-top: 10px;
    padding: 20px 30px 25px 30px;
    box-shadow: 0px 0px 5px 1px #f1f1f1;
    gap: 20px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    opacity: ${(props) => (props.disabled ? 0 : 1)} !important;
    input {
    }
    @media screen and (max-width: 640px) {
      overflow-y: visible;
      padding: 20px;
      padding-bottom: 30px;
    }
  }
`;

const InputContainer = styled.div`
  input {
    text-transform: capitalize;
  }
  &.checklist {
    margin-top: -20px;
    color: black;
    opacity: 0.8;
    label {
      min-width: 120px;
    }
    span {
      font-size: 14px;
      font-family: pt sans;
    }
    h5 {
      margin-top: 8px;
      margin-bottom: 10px;
      opacity: 0.8;
      font-weight: 500;
      font-size: 14px;
    }
    .ChecklistGroup {
      margin: 12px 10px;
    }
    svg {
      color: black;
    }
  }
  .select {
    width: 100%;
    min-width: 300px;
  }
  .locationLabel,
  .roleLabel {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12.5px;
    margin-left: 15px;
    margin-bottom: -7px;
    margin-top: -6px;
    display: flex;
    @media screen and (max-width: 640px) {
      // margin-top: 5px;
    }
  }
  .roleLabel {
    // margin-bottom: 10px;
  }
  &.LocationInput {
    margin-bottom: 2px;
    div {
      width: 90%;
    }
  }
  &.RoleInput {
    div {
      width: 90%;
    }
  }
`;

const SectionHeading = styled.h5``;

const Input = styled(TextField)`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 20px;
  font-family: Roboto;
  color: red !important;
  &.multiline {
    textarea {
      min-height: 100px;
    }
      margin-top: 10px;
  }
}
`;

const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0px 10px;
`;

const OrganizationHeading = styled.h5`
  padding-bottom: 10px;
`;

const Emoji = styled.span`
  display: flex;
  margin-right: 5px;
  margin-top: 7px;
  margin-left: 20px;
  margin-bottom: 0px;
`;

const Title = styled.h1`
  display: flex;
  font-size: 16.5px !important;
  color: #000;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.75px;
  margin-bottom: 0px !important;
  max-width: 95%;
  @media screen and (max-width: 768px) {
    max-width: 95%;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 0px;
  margin-left: 27px;
  margin-top: 0px;
  max-width: 80%;
  line-height: 22px;
  font-size: 14.5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  position: relative;
  bottom: 0px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 1px #f1f1f1;
  background: white;
  z-index: 2;
  border-radius: 0px 0px 5px 5px;
  width: 102%;
  margin-bottom: -5px;
  padding: 30px;
  gap: 10px;
  @media screen and (max-height: 768px) {
  }
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 16px 6px 16px;
  gap: 8px;
  height: 40px;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4;
  text-align: left;
  background: black;
  box-shad1 &:disabled {
    // Styles when the button is disabled
    background: rgb(185, 185, 185);
    cursor: not-allowed;
  }
  &:hover {
    background: #000;
  }
    &.SaveButton {
    background: white;
    color: black;
    border: 2px solid black;
    &:hover {
      background: #f1f1f1;

    }
`;

const Label = styled.span`
  font-weight: bold;
  border-radius: 3px;
  background: ${(props) => props.$color};
  color: white;
  padding: 8px 15px 7px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  font-family: "PT Sans", sans-serif;
  letter-spacing: 1px;
  margin: 20px;
  width: fit-content;
  animation: pulse 2.6s infinite ease-in-out;
  @keyframes pulse {
    0% {
      background-color: ${(props) => props.$color};
    }
    50% {
      background-color: ${(props) => props.theme.colors.black};
    }
    100% {
      background-color: ${(props) => props.$color};
    }
  }
`;

export const ProfileForm = () => {
  const {
    platformUser,
    hasProfile,
    setHasProfile,
    selectedNavItem,
    UserOrganization,
    setUserOrganization,
    setUserRole,
    userRole,
  } = useContext(AppContext);

  const [submit, setSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [saveMessage, setSaveMessage] = useState("Save Draft ?");
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    userLocation: "",
    userRoles: "",
    orgName: "",
    orgSize: "",
    orgDescription: "",
    userFname: "",
    userLname: "",
    gender: "",
    projectDescription: "",
    challengeDescription: "",
    profileUrl: "",
    supportOptions: {
      network: false,
      mentoring: false,
      funding: false,
      programs: false,
      resources: false,
    },
  });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchProfileData = useCallback(async () => {
    const {
      data: { user },
      error: userError,
    } = await supabase.auth.getUser();

    if (userError || !user) {
      console.error("No user found or error retrieving user:", userError);
      await delay(1000);
      setLoading(false);
      return;
    }

    const userID = user.id;

    // On fetch, populate form with user metadata
    const authLocation = user.user_metadata.custom?.location;
    const authRoles = user.user_metadata.custom?.roles;
    const authOrg = user.user_metadata.custom?.organization;

    if (authOrg) {
      // console.log("Auth Org:", authOrg);
      const matchingOrgItem = Orgs.find(
        (item) => item.route.toLowerCase() === authOrg.toLowerCase()
      );
      setUserOrganization(matchingOrgItem);
    }

    const { data: profile, error: noProfile } = await supabase
      .from("profiles")
      .select("profiledata, submitted")
      .eq("id", userID)
      .single();

    const submissionStatus = profile?.submitted || false;
    setSubmitted(submissionStatus);

    // const { data: profiletwo, error: errorTwo } = await supabase
    //   .from("profiles")
    //   .select("*")
    //   .range(0, 5);

    // console.log("this is error two" + errorTwo);
    // console.log("this is profile two" + profiletwo);

    // profiletwo.forEach((item, index) => {
    //   console.log(`Element ${index}:`, item);
    // });

    // If no profile, initialize form with auth metadata
    if (noProfile) {
      setHasProfile(false);
      setFormData((prevData) => ({
        ...prevData,
        userLocation: authLocation || "",
        userRoles: authRoles || "",
      }));

      // Set the userRole based on the Auth Role & initialize submit message
      setUserRole(authRoles);
      setSubmitMessage("Submit for Review");

      // If profile exists, populate form with profile data
    } else if (profile && profile.profiledata) {
      // if profile.submitted is true, set submit to true
      if (profile.submitted) {
        setSubmitted(true);
        setSubmitMessage("Update Profile?");
      } else {
        setSubmitted(false);
        setSubmitMessage("Submit for Review");
      }

      setFormData((prevData) => ({ ...prevData, ...profile.profiledata }));
      setHasProfile(true);
      setUserRole(profile.profiledata.userRoles);
    }
    await delay(1000);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchProfileData();
  }, [fetchProfileData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "userRoles" && !UserOrganization) {
      setUserRole(value);
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      supportOptions: {
        ...prevData.supportOptions,
        [name]: checked,
      },
    }));
  };

  const handleSave = async () => {
    setSaveMessage("Saving...");

    const {
      data: { user },
      error: userError,
    } = await supabase.auth.getUser();

    if (userError || !user) {
      console.error("No user found or error retrieving user:", userError);
      return;
    }

    const userID = user.id;
    // console.log("Saving form for user ID:", userID);

    const { data, error } = await supabase
      .from("profiles")
      .upsert([{ id: userID, profiledata: formData, email: platformUser.email }])
      .select();

    if (error) {
      console.error("Error saving profile:", error.message);
    } else {
      // console.log("Profile saved:", data);
      await delay(1000);
      setSaveMessage("Saved ✅");
      trackEvent("user_profile_updated");
      await delay(2000);
      setSaveMessage("Save Draft ?");
    }
    await fetchProfileData();
    // console.log("Form Saved:", formData);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmit(true);
    setSubmitMessage("Submitting...");
    await (delay(1000));
    const {
      data: { user },
      error: userError,
    } = await supabase.auth.getUser();

    if (userError || !user) {
      console.error("No user found or error retrieving user:", userError);
      return;
    }

    const userID = user.id;
    // console.log("Submitting form for user ID:", userID);

    const { data, error } = await supabase
      .from("profiles")
      .upsert([{ id: userID, profiledata: formData, submitted: true }])
      .select();

    if (error) {
      console.error("Error inserting/updating profile:", error.message);
    } else {
      // console.log("Profile inserted/updated:", data);
      await delay(1000);
      setSubmitMessage("Done!");
      trackEvent("user_profile_submitted");
      await delay(2000);
      setSubmit(false);
    }
    await fetchProfileData();
    // console.log("Form Submitted:", formData);
  };

  return (
    <>
      <ProfileSignUp className="ProfileSignup">
        {({ platformUser } && !submitted) || loading ? (
          <Label $color={selectedNavItem?.primarycolor}>
            You're Logged In!
          </Label>
        ) : (
          <Label $color="rgb(255, 79, 0)">Profile Under Review ...</Label>
        )}

        <ProfileHeader className="ProfileHeader">
          {loading ? (
            <>
              <Emoji>🔍</Emoji>
              <Title>Checking for profile...</Title>
            </>
          ) : !submitted ? (
            <>
              <Emoji>👩🏾‍💻</Emoji>
              <Title>Setup a profile.</Title>
            </>
          ) : (
            <>
              <Emoji>🏗️</Emoji>
              <Title>Profile found!</Title>
            </>
          )}
        </ProfileHeader>
        {!submitted || loading ? (
          <Paragraph>
            Please ensure your information is completed in full and accurate for
            human review.
          </Paragraph>
        ) : (
          <Paragraph>
            Our Intake team will review your profile & you will receive an email
            once complete. {" "}
            <Link to="/news/release-notes-0-1">
              <u> Learn more here.</u>
            </Link>
          </Paragraph>
        )}
        <form disabled={loading} onSubmit={handleSubmit}>
          <SectionHeading className="ProfileHeading">My Profile</SectionHeading>
          <FormHelperText>
            For community safety, your identity will be verified. 🪪
          </FormHelperText>
          <InputContainer className="RoleInput">
            <FormControl fullWidth>
              <InputLabel shrink id="role-select-label">
                Role
              </InputLabel>
              <Select
                name="userRoles"
                labelId="role-select-label"
                id="role-select"
                label="Role"
                value={loading ? "" : formData.userRoles}
                onChange={handleInputChange}
                displayEmpty
                size="small"
                disabled={loading || submit}
                renderValue={(selected) => {

                  if (loading) {
                    return "...";
                  }

                  if (!selected) {
                    return "🔎 Select your Role";
                  }

                  const roleData = NavItems.find(
                    (navItem) => navItem.role === selected
                  );

                  if (roleData) {
                    const { emoji, role } = roleData;
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {emoji} &nbsp;
                        {role}
                      </div>
                    );
                  }
                  return selected;
                }}
              >
                {NavItems.map(({ role, emoji }) => (
                  <MenuItem key={role} value={role}>
                    {emoji} {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </InputContainer>
          <InputContainer className="LocationInput">
            <FormControl fullWidth>
              <InputLabel
                shrink
                // className="locationLabel"
                id="location-select-label"
              >
                Location
              </InputLabel>
              <Select
                name="userLocation"
                labelId="location-select-label"
                id="location-select"
                label="Location"
                value={loading ? "..." : formData.userLocation}
                onChange={handleInputChange}
                displayEmpty
                size="small"
                disabled={loading || submit}
                renderValue={(selected) => {
                  if (!selected) {
                    return "🔎 Select your Location";
                  }
                  const provinceData = Object.entries(provinces).find(
                    ([_, { cities }]) =>
                      cities.some((city) => city.name === selected)
                  );
                  if (provinceData) {
                    const [_, { name, image }] = provinceData;
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {image} &nbsp;
                        {selected}
                      </div>
                    );
                  }
                  return selected;
                }}
              >
                {Object.entries(provinces).map(
                  ([value, { name, image, cities }]) => [
                    <ListSubheader key={value}>
                      {image} {name}
                    </ListSubheader>,
                    ...cities.map((city) => (
                      <MenuItem key={city.name} value={city.name}>
                        {city.name}
                      </MenuItem>
                    )),
                  ]
                )}
              </Select>
            </FormControl>
          </InputContainer>

          <InputContainer>
            <Input
              type="text"
              label="First Name"
              variant="outlined"
              size="small"
              name="userFname"
              value={loading ? "..." : formData.userFname}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              disabled={loading || submit}
            />
          </InputContainer>
          <InputContainer>
            <Input
              type="text"
              label="Last Name"
              variant="outlined"
              size="small"
              name="userLname"
              value={loading ? "..." : formData.userLname}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              disabled={loading || submit}
            />
          </InputContainer>

          <InputContainer>
            <FormControl fullWidth>
              <InputLabel shrink id="gender-select-label">
                Gender
              </InputLabel>
              <Select
                name="gender"
                labelId="gender-select-label"
                id="gender-select"
                label="Gender"
                value={loading ? "" : formData.gender}
                onChange={handleInputChange}
                displayEmpty
                size="small"
                disabled={loading || submit}
                renderValue={(selected) => {
                  if (loading) return "...";
                  if (!selected) return "🔎 Select your Gender";
                  return selected;
                }}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Non-binary">Non-binary</MenuItem>
                <MenuItem value="Prefer-not-to-say">Prefer not to say</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText>
              Defines eligibility for Women Founder programs.
            </FormHelperText>
          </InputContainer>
          <OrganizationHeading>Organization Details</OrganizationHeading>
          <InputContainer>
            <Input
              type="text"
              label="Organization Name"
              variant="outlined"
              size="small"
              name="orgName"
              value={loading ? "..." : formData.orgName}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              disabled={loading || submit}
            />
          </InputContainer>
          <InputContainer>
            <FormControl fullWidth>
              <InputLabel shrink className="orgSizeLabel" id="orgSize-label">
                Organization Size
              </InputLabel>
              <Select
                labelId="orgSize-label"
                label="Organization Size"
                id="org-size"
                value={loading ? "" : formData.orgSize}
                name="orgSize"
                onChange={handleInputChange}
                displayEmpty
                size="small"
                disabled={loading || submit}
                renderValue={(selected) => {
                  if (loading) return "...";
                  if (!selected) return "🔎 Select Organization Size";
                  return selected;
                }}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="3 - 10">3 - 10</MenuItem>
                <MenuItem value="12 - 25">11 - 25</MenuItem>
                <MenuItem value="26 - 50">26 - 50</MenuItem>
                <MenuItem value="51 - 100">51 - 100</MenuItem>
                <MenuItem value="100+">100 +</MenuItem>
              </Select>
            </FormControl>
          </InputContainer>
          <InputContainer>
            <Input
              className="multiline"
              type="text"
              label="Just a few words about your company"
              variant="outlined"
              size="small"
              multiline
              name="orgDescription"
              value={loading ? "..." : formData.orgDescription}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              disabled={loading || submit}
            />
          </InputContainer>
          {/* <SectionHeading>Project Details</SectionHeading>
          <InputContainer>
            <Input
              className="multiline"
              type="text"
              label="Describe your current project"
              variant="outlined"
              size="small"
              multiline
              name="projectDescription"
              value={loading ? "..." : formData.projectDescription}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              disabled={loading || submit}
            />
          </InputContainer>

          <InputContainer>
            <Input
              className="multiline"
              type="text"
              label="What's your biggest challenge?"
              variant="outlined"
              size="small"
              multiline
              name="challengeDescription"
              value={formData.challengeDescription}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              disabled={loading || submit}
            />
          </InputContainer> */}
          <InputContainer>
            <Input
              type="text"
              label="Organization or Personal URL"
              variant="outlined"
              size="small"
              name="profileUrl"
              value={loading ? "..." : formData.profileUrl}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              disabled={loading || submit}
            />
          </InputContainer>

          <OrganizationHeading>Goals</OrganizationHeading>

          <InputContainer className="checklist">
            <SectionHeading className="SupportHeading">
              Can we support with any of the following?
            </SectionHeading>
            <div className="ChecklistGroup">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.supportOptions.network}
                    onChange={handleCheckboxChange}
                    name="network"
                    disabled={loading || submit}
                  />
                }
                label="Network"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.supportOptions.mentoring}
                    onChange={handleCheckboxChange}
                    name="mentoring"
                    disabled={loading || submit}
                  />
                }
                label="Mentoring"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.supportOptions.funding}
                    onChange={handleCheckboxChange}
                    name="funding"
                    disabled={loading || submit}
                  />
                }
                label="Funding"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.supportOptions.programs}
                    onChange={handleCheckboxChange}
                    name="programs"
                    disabled={loading || submit}
                  />
                }
                label="Programs"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.supportOptions.resources}
                    onChange={handleCheckboxChange}
                    name="resources"
                    disabled={loading || submit}
                  />
                }
                label="Resources"
              />
            </div>
          </InputContainer>
        </form>
      </ProfileSignUp>
      <ButtonContainer className="ButtonContainer">
        {!loading && !submitted ?
          (<Button className="SaveButton" onClick={handleSave} type="submit" disabled={loading}>
            {saveMessage}
          </Button>
          ) : (""
          )}
        <Button onClick={handleSubmit} type="submit" disabled={loading}>
          {loading ? "Loading..." : submitMessage}
        </Button>
      </ButtonContainer>
    </>
  );
};
