import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { NavItems, Orgs } from "./navutilities";
import { useNavigate, useLocation, Link } from "react-router-dom";
import AppContext from "AppContext";
import mobilebg from "assets/mobilebg.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import HomeFAQs from "./homefaqs";
import Intercom from "@intercom/messenger-js-sdk";
import orgLogo from "assets/orglogo.png";
import { useRef } from "react";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import RecentActorsSharpIcon from "@mui/icons-material/RecentActorsSharp";
import { configureGA } from "website/ga4utilities";
import ReactDOM from 'react-dom'
import Snowfall from 'react-snowfall'

const Site = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh; // Full viewport height
  padding-top: 75px; // Adjust this based on your TopNav height
  padding-top: ${(props) => (props.$mobileMenu ? "0px" : "75px")};
  box-sizing: border-box;
  overflow: visible;
`;

// Body component
const BodyContainer = styled.div`
  background: #f7f7f7;
  display: flex;
  flex-direction: column; // Stack Hero and Links
  scrollbar-width: thin;
  padding: 20px;
  transition: all 0.2s ease;
  flex: 1;
  box-sizing: border-box;
  gap: 5px;
  overflow: hidden;
  // display: ${(props) => (props.$isvisible ? "flex" : "none")};
  // max-height: 85vh;
  @media screen and (max-width: 800px) {
    overflow: scroll;
    padding: 20px;
  }
  @media screen and (max-width: 640px) {
    padding: 15px 10px;
    overflow: scroll;
  }
`;

const Hero = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1; // Allow Hero to fill available height
  box-shadow: 0px 4px 42px 20px rgba(150, 150, 150, 0.12);
  border-radius: 15px;
  overflow: hidden; // Prevent scrolling
  z-index: 5;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    // overflow: visible;
  }
  @media screen and (max-width: 640px) {
    // overflow: visible;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  background: white;
  width: 100%;
`;

const LeftComponent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 300px; // This can stay as is
  box-sizing: border-box;
  transition: background 0.2s ease;
  gap: 40px;
  border-radius: 10px 0px 0px 10px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    border-radius: 15px 15px 0px 0px;
    height: initial;
    flex: 0.2;
    min-width: initial;
  }
`;

const RightComponent = styled.div`
  flex: ${(props) => (!props.$blog ? 3 : 2)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: white;
  border-radius: 0px 10px 10px 0px;
  padding: 30px;
  box-sizing: border-box;
  transition: all 0.2s ease;
  overflow: hidden;
  flex-direction: column;
  transition: all 0.5s ease;
  @media screen and (max-width: 1280px) {
    flex: 4;
    padding: 20px;
  }
  @media screen and (max-width: 800px) {
    align-items: flex-start;
    border-radius: 0px 0px 15px 15px;
  }
  @media screen and (max-height: 740px) {
    align-items: flex-start;
  }
  @media screen and (max-width: 640px) {
    padding: 5px;
    border-radius: 0px 0px 10px 10px;
  }
`;

// TopNav component
const TopNav = styled.nav`
  width: 100%;
  height: 75px;
  display: flex;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 4px 44px 0px rgba(178, 178, 178, 0.25);
  font-weight: 700;
  text-transform: uppercase;
  align-items: center;
  font-family: pt sans;
  font-size: 13px;
  padding: 20px 0px 20px 20px;
  box-sizing: border-box;
  gap: 20px;
  transition: all 0.2s ease;
  justify-content: space-between;
  background: white;
  z-index: 1;
  position: fixed;
  top: 0px;
  @media screen and (max-width: 1024px) {
    padding: 14.5 40px 12.5px 40px;
  }
  @media screen and (max-width: 640px) {
    justify-content: flex-start;
    gap: 10px;
  }
`;

const Logo = styled.img`
  width: 80px;
  height: auto;
  // position: absolute;
  left: 20px;
  display: flex;
  justify-self: flex-start;
`;

const Menu = styled.div`
  display: flex;
  list-style: none;
  gap: 8px;
  transition: all 0.2s ease;
  display: ${(props) => (!props.$isvisible ? "flex" : "none")};
  width: ${(props) => (props.$loggedIn ? "100%" : "initial")};

  @media screen and (max-width: 1024px) {
    overflow-x: scroll;
    padding-bottom: 8px;
    scrollbar-width: none;
  }
  @media screen and (max-width: 640px) {
    display: none;
  }
`;

const Button = styled.div`
  min-height: 40px;
  border-radius: 3px;
  padding: 12.5px 20px;
  box-sizing: border-box;
  color: black;
  font-size: 13px;
  white-space: nowrap;
  align-content: center;
  transition: all 0.2s ease;
  // background: red;
  &:hover {
    background-color: black;
    color: white;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    height: fit-content;
  }
`;

const Login = styled.div`
  height: 75px;
  box-sizing: border-box;
  width: fit-content;
  padding: 15px 20px;
  box-shadow: 0px 4px 44px 0px rgba(178, 178, 178, 0.25);
  // position: absolute;
  right: 0px;
  top: 0px;
  z-index: 5;
  align-items: center;
  display: flex;
  @media screen and (max-width: 1024px) {
    // padding: 15px 20px;
  }
  @media screen and (max-width: 640px) {
    justify-self: flex-end;
    right: 0px;
    position: absolute;
  }
`;

const LoginButton = styled(Button)`
  background: black;
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
  text-decoration: none;
  &:hover {
    background: #f7f7f7;
    color: black;
  }
`;

const ProfileButton = styled(LoginButton)`
  background: white;
  border: 2px solid black;
  color: black;
`;

const NavItem = styled(Button)`
  color: black;
  &:hover {
    background-color: ${(props) => props.$hoverColor} !important;
    color: white !important;
  }
  @media screen and (max-width: 1024px) {
  }
  &.NewsNav {
    background-color: rgb(241, 241, 241);
    &:hover {
      background: black;
    }
  };
  ${(props) =>
    props.$isSelected ? `
      background-color: black !important;
      text-decoration: none !important;
      color: white !important;
      border-radius: 3px;
    ` : ""
  }
  

`;

const ProfileNavItem = styled(NavItem)`
  background: black;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  @media screen and (max-width: 1024px) {
    margin-top: 10px;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.red} !important;
    background: red;
  }
  svg {
    margin-top: 2px;
    margin-right: 8px;
  }
`;

const BottomNavLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; // Allow wrapping of links
  gap: 30px;
  box-sizing: border-box;
  padding: 20px;
  justify-content: center;
  width: 100%;
  align-content: start;
  background: white; // Set background for visibility
  @media screen and (max-width: 910px) {
    padding: 10px;
    gap: 20px;
  }
  @media screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px 5px;
    padding: 15px 0px; // Adjust for mobile spacing
  }

  span {
    font-size: 11px;
    letter-spacing: 1px;
    font-family: pt sans;
    text-transform: uppercase;
    transition: all 0.2s ease;
    height: fit-content;
    font-weight: 700;
  }
  &:hover {
    color: black;
    cursor: pointer;
    transition: all 0.2s ease;
  }
`;

const BottomLink = styled(Link)`
  color: rgb(173, 173, 173);
  text-decoration: none !important;
  padding: 2px;
  transition: all 0.2s ease;
  ${(props) =>
    props.$isSelected ? `
      background-color: black !important;
      text-decoration: none !important;
      color: white !important;
      padding: 5px 10px;
      box-sizing: border-box;
      border-radius: 3px;
    ` : ""
  }
  &.small {
    font-size: 28px;
    letter-spacing: 0px;
  }
  &:hover {
    color: black;
    cursor: pointer;
    transition: all 0.2s ease;
  }
`;

const MobileMenuButton = styled(MoreVertIcon)`
  color: grey;
  border-radius: 20px;
  box-sizing: border-box;
  margin-right: -5px;
  margin-left: -10px;
  @media screen and (min-width: 640px) {
    display: none !important;
  }
  &:hover {
    color: red;
  }
  &:active {
    transform: scale(0.5);
    transition: all 0.2s ease;
  }
`;

const MobileMenuClose = styled(MoreHorizIcon)`
  color: red;
  border-radius: 20px;
  box-sizing: border-box;
  margin-right: -5px;
  margin-left: -10px;
  @media screen and (min-width: 640px) {
    display: none !important;
  }
  &:hover {
    color: black;
  }
  &:active {
    transform: scale(0.5);
    transition: all 0.2s ease;
  }
`;

const MobileMenu = styled.div`
  width: 100%;
  background: url(${mobilebg});
  background-size: cover;
  background-position: center;
  z-index: 50000;
  display: ${(props) => (props.$isvisible ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 75px;
  span {
    text-align: flex-start;
    background: white;
    width: fit-content;
    padding: 20px 25px 10px;
    border-radius: 5px;
    a {
      color: black;
      text-decoration: none;
      font-family: "DIN Condensed";
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 5px;
      text-transform: uppercase;
      @media screen and (max-width: 400px) {
        font-size: 30px;
        letter-spacing: 3px;
      }
    }
    &:hover {
      color: white;
      background: black;
      transition: all 0.2s ease;
      cursor: pointer;
      a {
        color: white;
      }
    }
  }
`;

const MobileNavList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 8px;
  margin-bottom: 5px;
  // margin: 0 auto 40px;
  padding: 20px 20px 30px;
  @media screen and (max-width: 420px) {
    padding: 5px 10px 10px;
  }
`;

export default function HomePage({ pageName, leftTitle, pageForm }) {

  useEffect(() => {
    const appElement = document.querySelector('#snow');
    if (appElement) {
      const root = ReactDOM.createRoot(appElement);
      root.render(
        <div style={{
          zIndex: 1000,
          height: '100%', 
          width: '100%', 
          background: 'transparent', 
          position: 'absolute',
          color: '#607d8b',
          pointerEvents: 'none', // Prevent blocking clicks

        }}>
          <Snowfall />
        </div>
      );
    }
  }, []);

  Intercom({
    app_id: "gkcn4bpc",
  });

  // useEffect(() => {
  //   const loadIntercom = () => {
  //     // Initialize Intercom settings
  //     window.intercomSettings = {
  //       app_id: "gkcn4bpc"
  //     };

  //     // Check if Intercom is already loaded
  //     if (typeof window.Intercom === "function") {
  //       window.Intercom("reattach_activator");
  //       window.Intercom("update", window.intercomSettings);
  //     } else {
  //       // Create a script element to load Intercom
  //       const script = document.createElement("script");
  //       script.type = "text/javascript";
  //       script.async = true;
  //       script.src = "https://widget.intercom.io/widget/gkcn4bpc";

  //       // Append the script to the document
  //       document.head.appendChild(script);

  //       // Once the script is loaded, initialize Intercom
  //       script.onload = () => {
  //         window.Intercom("boot", window.intercomSettings);

  //         // Check if this is first visit using localStorage
  //         const hasVisited = localStorage.getItem('intercomShown');
  //         if (!hasVisited) {
  //           // Show messenger on first visit
  //           window.Intercom('show');
  //           // Set flag in localStorage
  //           localStorage.setItem('intercomShown', 'true');
  //         }
  //       };
  //     }
  //   };

  //   loadIntercom();
  // }, []);

  const {
    platformUser,
    selectedNavItem,
    setSelectedNavItem,
    userRole,
    setUserRole,
    userOrganization,
    setUserOrganization,
    selectedPost,
    setSelectedPost,
    selectedLink,
    setSelectedLink,
  } = useContext(AppContext);

  useEffect(() => {
    if (platformUser) {
      configureGA(platformUser?.id);
    }
  }, [platformUser]); // Only runs when `userId` is available

  // ## Mobile Menu
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenu = (to) => {
    setMenuOpen((prev) => !prev); // Use functional update to toggle
  };

  // ## Navigation
  const navigate = useNavigate();

  // Match url path to navItem to set that state.
  const location = useLocation();
  const currentPath = location.pathname.substring(1);

  // Matching Nav Route
  const matchingNavRoute = NavItems.find(
    (item) => item.route.toLowerCase() === currentPath.toLowerCase()
  );
  // Matching User Role
  const matchingUserRole = NavItems.find(
    (item) =>
      item.role && item.role.toLowerCase() === String(userRole).toLowerCase()
  );

  // Check for Organization URL
  useEffect(() => {
    if (currentPath.toLowerCase().startsWith("org/")) {
      const orgPath = currentPath.replace(/^org\//, "");
      const currentOrg = Orgs.find((org) => org.route === orgPath);
      setUserOrganization(currentOrg);
    }
  }, [currentPath, setUserOrganization, userOrganization, userRole]);

  // Initialize page
  useEffect(() => {
    // First check for organizations
    if (userOrganization) {
      setSelectedNavItem(userOrganization);

      // If not logged in && User Role False
    } else if (!platformUser && !userRole) {
      setSelectedNavItem(matchingNavRoute);

      // If not logged in && User Role True
    } else if (!platformUser) {
      userRole
        ? setSelectedNavItem(matchingUserRole)
        : setSelectedNavItem(matchingNavRoute);

      // If logged in and user role
    } else if (platformUser && userRole) {
      setSelectedNavItem(matchingUserRole);
      // otherwise
    } else {
      setSelectedNavItem(null);
    }
  }, [userRole, userOrganization, matchingNavRoute, platformUser]);

  // Check if selectedPost's route matches any BottomLinks
  const bottomLinks = [
    "news",
    "terms",
    "privacy",
    "guidelines",
    "indigenous-relations",
    "investor-relations",
    "copyright",
  ];
  // In home.js
  useEffect(() => {
    if (selectedPost) {
      // If there's a selected post, set the link based on the post
      setSelectedLink(bottomLinks.includes(selectedPost.slug) ? selectedPost.slug : "news");
    } else if (selectedNavItem?.route !== "news") {
      // Clear selection when navigating away from news
      setSelectedLink(null);
    }
  }, [selectedPost, selectedNavItem]);

  // Utilities
  const NavUpdate = (item) => {
    setSelectedLink("");
    setUserRole("");
    setUserOrganization(null);
    setSelectedPost(null);
    if (menuOpen) setMenuOpen(false);
    navigate(`/${item.route}`);
  };

  const resetNav = () => {
    setSelectedNavItem(null);
  };

  const selectBlog = (route) => {
    setMenuOpen(false);
    resetNav();
    setSelectedLink(route); // Update selected link
    setSelectedPost(null);
    // add scroll to top here
    navigate(route); // Navigate to the clicked link
  };

  return (
    <div id="homepage">
      <MobileMenu $isvisible={menuOpen}>
        <MobileNavList>
          {platformUser ? (
            <>
              <ProfileNavItem onClick={() => NavUpdate("/profile")}>
                {selectedPost || selectedLink ? (
                  <>
                    <ExitToAppOutlinedIcon
                      style={{ transform: "rotate(180deg)" }}
                    />{" "}
                    Back to Profile ?
                  </>
                ) : (
                  <>
                    <RecentActorsSharpIcon />
                    {platformUser.email} 👋
                  </>
                )}
              </ProfileNavItem>
              <span>
                <BottomLink
                  onClick={() => selectBlog("/news")}
                  to="/news"
                  $isSelected={selectedLink === "news"}
                >
                  news!
                </BottomLink>
              </span>
              <span>
                <BottomLink
                  onClick={() => selectBlog("/terms")}
                  to="/terms"
                  $isSelected={selectedLink === "terms"}
                >
                  Terms
                </BottomLink>
              </span>
              <span>
                <BottomLink
                  onClick={() => selectBlog("/privacy")}
                  to="/privacy"
                  $isSelected={selectedLink === "privacy"}
                >
                  Privacy
                </BottomLink>
              </span>
              <span>
                <BottomLink
                  onClick={() => selectBlog("/guidelines")}
                  to="/guidelines"
                  $isSelected={selectedLink === "guidelines"}
                >
                  Guidelines
                </BottomLink>
              </span>
              <span>
                <BottomLink
                  onClick={() => selectBlog("/indigenous-relations")}
                  to="/indigenous-relations"
                  $isSelected={selectedLink === "indigenous-relations"}
                >
                  Indigenous Relations
                </BottomLink>
              </span>
              <span>
                <BottomLink
                  onClick={() => selectBlog("/investor-relations")}
                  to="/investor-relations"
                  $isSelected={selectedLink === "investor-relations"}
                >
                  Investor Relations
                </BottomLink>
              </span>
              <span>
                <BottomLink
                  className="small"
                  onClick={() => selectBlog("/copyright")}
                  to="/copyright"
                  $isSelected={selectedLink === "copyright"}
                >
                  © 2024 Canada Innovates &trade;
                </BottomLink>
              </span>
            </>
          ) : (
            <>
              <span>
                <Link onClick={NavUpdate} to="/">
                  Home
                </Link>
              </span>
              {/* <span>
                <Link onClick={NavUpdate} to="/organizations">
                  Organizations
                </Link>
              </span> */}
              <span>
                <Link onClick={NavUpdate} to="/community">
                  Community
                </Link>
              </span>
              <span>
                <Link onClick={NavUpdate} to="/Founders">
                  Founders
                </Link>
              </span>
              <span>
                <Link onClick={NavUpdate} to="/ecosystems">
                  Ecosystems
                </Link>
              </span>
              <span>
                <Link onClick={NavUpdate} to="/service-providers">
                  Service Providers
                </Link>
              </span>
              <span>
                <Link onClick={NavUpdate} to="/news">
                  News
                </Link>
              </span>
            </>
          )}
        </MobileNavList>
      </MobileMenu>

      <div id="snow" />
      <Site $mobileMenu={menuOpen}>
        <NavContainer>
          <TopNav>
            {menuOpen ? (
              <MobileMenuClose onClick={handleMenu} />
            ) : (
              <MobileMenuButton onClick={handleMenu} />
            )}
            <Logo src={orgLogo} alt="Logo" />
            <Menu $isvisible={menuOpen} $loggedIn={platformUser}>
              {!platformUser ? (
                <>
                  {NavItems.map((item, index) => (
                    <NavItem
                      key={index}
                      $hoverColor={item.primarycolor}
                      style={{
                        backgroundColor:
                          item === selectedNavItem
                            ? selectedNavItem?.primarycolor
                            : "#f1f1f1",
                        color: item === selectedNavItem ? "white" : "black",
                      }}
                      onClick={() => NavUpdate(item)}
                    >
                      {item.name === "Welcome" ? "home" : item.name}
                    </NavItem>
                  ))}
                  <NavItem
                    className="NewsNav"
                    $isSelected={selectedLink === "news"}
                    onClick={() => selectBlog("/news")}>
                    News</NavItem>
                </>
              ) : (
                <>
                  <ProfileNavItem onClick={() => NavUpdate("/profile")}>
                    {selectedPost || selectedLink ? (
                      <>
                        <ExitToAppOutlinedIcon
                          style={{ transform: "rotate(180deg)" }}
                        />{" "}
                        Back to Profile ?
                      </>
                    ) : (
                      <>
                        <RecentActorsSharpIcon />
                        {platformUser.email} 👋
                      </>
                    )}
                  </ProfileNavItem>
                </>
              )}
            </Menu>
            <Login>
              {!platformUser ? (
                <LoginButton as={Link} to="/login">
                  Login
                </LoginButton>
              ) : (
                <LoginButton as={Link} to="/login">
                  Logout
                </LoginButton>
              )}
            </Login>
          </TopNav>
        </NavContainer>

        <BodyContainer className="BodyContainer">
          <Hero className="Hero">
            <LeftComponent className="LeftComponent">
              {leftTitle ? leftTitle : null}
            </LeftComponent>
            <RightComponent
              className="RightComponent"
              $menu={menuOpen}
              $blog={selectedNavItem}
            >
              {pageForm}
            </RightComponent>
          </Hero>
        </BodyContainer>
        <BottomNavLinks>
          <span>
            <BottomLink
              onClick={() => selectBlog("/news")}
              to="/news"
              $isSelected={selectedLink === "news"}
            >
              News
            </BottomLink>
          </span>
          <span>
            <BottomLink
              onClick={() => selectBlog("/terms")}
              to="/terms"
              $isSelected={selectedLink === "terms"}
            >
              Terms
            </BottomLink>
          </span>
          <span>
            <BottomLink
              onClick={() => selectBlog("/privacy")}
              to="/privacy"
              $isSelected={selectedLink === "privacy"}
            >
              Privacy
            </BottomLink>
          </span>
          <span>
            <BottomLink
              onClick={() => selectBlog("/guidelines")}
              to="/guidelines"
              $isSelected={selectedLink === "guidelines"}
            >
              Guidelines
            </BottomLink>
          </span>
          <span>
            <BottomLink
              onClick={() => selectBlog("/indigenous-relations")}
              to="/indigenous-relations"
              $isSelected={selectedLink === "indigenous-relations"}
            >
              Indigenous Relations
            </BottomLink>
          </span>
          <span>
            <BottomLink
              onClick={() => selectBlog("/investor-relations")}
              to="/investor-relations"
              $isSelected={selectedLink === "investor-relations"}
            >
              Investor Relations
            </BottomLink>
          </span>
          <span>
            <BottomLink
              onClick={() => selectBlog("/copyright")}
              to="/copyright"
              $isSelected={selectedLink === "copyright"}
            >
              © 2024 Canada Innovates &trade;
            </BottomLink>
          </span>
        </BottomNavLinks>
      </Site>
      <div id="snow" />
    </div>
  );
}
