import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListSubheader from "@mui/material/ListSubheader";
import { provinces } from "./siteutilities";
import { Counter } from "./counter";
import { supabase } from "supabaseClient";
import AppContext from "AppContext";
import { keyframes } from "styled-components";
import { NavItems } from "./navutilities";
import { trackEvent } from "website/ga4utilities";

const isWindows = /Windows/.test(navigator.userAgent);

const Button = styled.div`
  min-height: 40px;
  border-radius: 3px;
  padding: 12.5px 20px;
  box-sizing: border-box;
  color: black;
  font-size: 13px;
  white-space: nowrap;
  align-content: center;
  transition: all 0.2s ease;
  // background: red;
  &:hover {
    background-color: black;
    color: white;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    height: fit-content;
  }
`;

const pulse = keyframes`
  0% {
    background-color: ${(props) => props.$color};
  }
  50% {
    background-color: ${(props) => props.theme.colors.black};
  }
  100% {
    background-color: ${(props) => props.$color};
  }
`;

const Label = styled.span`
  font-weight: bold;
  border-radius: 3px;
  background: ${(props) => props.$color};
  color: white;
  padding: 8px 15px 7px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  font-family: "PT Sans", sans-serif;
  letter-spacing: 1px;
  width: fit-content;
  animation: ${(props) => (props.$pulse ? pulse : "none")} 2.6s infinite
    ease-in-out;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 0px 5px;
`;

const Emoji = styled.span`
  display: flex;
  margin-right: 5px;
  margin-top: 7px;
`;

const Title = styled.h1`
  display: flex;
  font-size: 16.5px;
  color: #000;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.75px;
  margin-bottom: 0px;
  max-width: 95%;
  @media screen and (max-width: 768px) {
    max-width: 95%;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 0px;
  margin-left: 27px;
  margin-left: 2px;
  margin-top: 0px;
  max-width: 95%;
  line-height: 22px;
  font-size: 14.5px;
  a {
  font-size: 14px;
  color: ${(props) => props.$color};
  margin-left: 2px;
  font-weight: 700;
  }
  @media screen and (max-width: 768px) {
    // margin-left: 10px;
  }
`;

const CounterLabel = styled.div`
  background: #f1f1f1;
  color: black;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 3px;
  margin: 10px 0px -5px;
  font-size: 14px;
  font-weight: 600;
`;

const Graphic = styled.div`
  width: 100%;
  min-height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  // background: #f7f7f7;
  border-radius: 2px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: 640px) {
    // margin: 20px 0px 5px -10px;
    min-height: initial;
  }
`;

const EmailInput = styled(TextField)`
  font-family: DIN CONDENSED !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 700 !important;
  letter-spacing: 1.5px;
  @media screen and (max-width: 768px) {
    margin: 0px 0px 20px !important;
  }
`;

const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  position: sticky; /* Sticky to stay visible at the bottom */
  bottom: 0; /* Stick to the bottom of the container */
  box-sizing: border-box;
  box-shadow: 0 0 10px 2px rgba(163, 163, 163, 0.24);
  padding: 20px;
  overflow: visible;
  height: 100px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  @media screen and (max-width: 640px) {
    padding: 15px;
  }
`;

const Submit = styled(Button)`
  font-family: DIN CONDENSED, sans-serif;
  font-size: ${(props) => (isWindows ? "30px" : "36px")};
  padding: ${(props) => (isWindows ? "20px 20px" : "20px 10px 10px")};
  text-transform: uppercase;
  letter-spacing: 1px;
  background: ${(props) => props.$color};
  color: white;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  bottom: 0px;
  height: fit-content;
  &:hover {
    background: ${(props) => props.$color2};
    color: white;
  }
    @media screen and (max-width: 1024px) {
    font-size: 32px;
}|
  @media screen and (max-width: 640px) {
    font-size: 24px;
  }
  ${(props) =>
    props.$loading &&
    `
    animation: pulse 2s infinite ease-in-out;
    @keyframes pulse {
      0% {
        background-color: ${props.$color};
      }
      50% {
        background-color: ${props.theme.colors.black};
      }
      100% {
        background-color: ${props.$color};
      }
    }
  `}
`;

const SelectDropdown = styled(Select)`
  width: 100%;
  height: 60px;
  margin: 0px 0px 5px 0px;
  font-family: DIN CONDENSED;
  font-weight: 700;
  // text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 1px;
  text-align: left;
  box-shadow: none;
  background: white;
  border: none;
  border: 1px solid #f0f0f0;
  background: #f7f7f7;
  box-sizing: border-box;
  padding: 12px 20px 10px 0px;
`;

const SelectDropwdownPlaceholder = styled(MenuItem)`
  opacity: 0.6;
`;

const Form = styled(FormControl)`
  width: 100%;
  display: flex;
`;

const Signup = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  flex: 1;
  background: #fff;
  box-shadow: 0 0 10px 2px rgba(163, 163, 163, 0.24);
  border-radius: 10px 10px 0px 0px;
  padding: 30px 20px;
  position: relative;
  transition: all 0.2s ease;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 0px;
  @media screen and (max-width: 1100px) {
    transition: height 0.2s ease;
    justify-content: flex-start;
  }
  @media screen and (max-width: 768px) {
    height: 100%;
    transition: height 0.2s ease;
    padding: 20px 15px 0px;
  }
  @media screen and (max-height: 720px) {
    overflow-y: auto;
  }
`;

const SignupContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background: white;
  padding-bottom: 25px;
  h5 {
    margin: 10px 0px -5px 2px;
  }
  // &:after {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   height: 40%; /* Adjust to cover part of the container */
  //   background: url("https://images.unsplash.com/photo-1605106715994-18d3fecffb98?q=80&w=1863&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   opacity: 0.5; /* Adjust the opacity for blending with the gradient */
  // }


`;

const Note = styled.div`
  margin-top: 20px;
  font-size: 14px;
  padding: 10px 20px 25px;
  background: #f7f7f7;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
  h4 {
    margin-bottom: 15px;
  }
`;

const RegistrationHero = styled.div`
  gap: 5px;
  background: #f7f7f7;
  padding: 0px 25px 30px;
  margin: 20px 0px;
  border-radius: 5px;
  background: ${(props) => props.$color};
  border: 3px solid #f7f7f7; 
  &:hover, &:focus, &:active {
    border: 5px solid ${(props) => props.$color};
    transition: all 0.2s ease;
  }
   background: white;
  background: #f7f7f7;
  // color: white;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  li {
  padding-bottom: 5px;
  font-size: 15px;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 15px;
  color: ${(props) => props.$color};
    }
`;

export default function HomeSignup({ pageName, menu }) {
  const {
    platformUrl,
    selectedNavItem,
    platformUser,
    userRole,
    setUserRole,
    userOrganization,
  } = useContext(AppContext);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // ## Registration
  const [selectedLocation, setSelectedLocation] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [hasBlurred, setHasBlurred] = React.useState(false); // Track if the user has blurred the input
  const [buttonMessage, setButtonMessage] = useState("Create My Account");
  const [emptyState, setEmptyState] = useState(true); // Track if the email input is empty
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleLocationInput = (event) => {
    event.preventDefault();
    setSelectedLocation(event.target.value);
  };

  const validateEmail = (emailToCheck) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    // Check if email is empty
    if (!emailToCheck) {
      setEmailValid(false);
      setButtonMessage("Your email, please?");
      setEmptyState(true); // Set emptyState to true if email is empty
      return false;
    }

    // Check if email format is invalid
    if (!emailRegex.test(emailToCheck)) {
      setEmailValid(false);
      setButtonMessage("Use a valid email...");
      setEmptyState(false); // Email is not empty, so emptyState is false
      return false;
    } else {
      setEmailValid(true);
      setButtonMessage("Create My Account");
      setEmptyState(false); // Email is valid, so emptyState is false
      return true;
    }
  };

  const handleEmailInput = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);

    // Exit empty state if the user starts typing
    if (emptyState && emailValue) {
      setEmptyState(false);
      setButtonMessage("Create My Account");
    }

    // Only re-validate while typing if the user has already blurred the input once
    if (hasBlurred) {
      // validateEmail(emailValue);
    }
  };

  const handleBlur = (event) => {
    const emailValue = event.target.value;
    if (!emptyState) {
      setHasBlurred(true); // Mark that the user has blurred the input
    } else {
      setHasBlurred(false);
    }
    // Validate the email on blur
    validateEmail(emailValue);
  };

  const handleRegistration = async (event) => {
    event.preventDefault();

    const emailValue = email || "";

    // Prevent multiple submissions
    if (isSubmitted) {
      return false;
    }

    // Check if the email is empty and set emptyState
    if (!emailValue) {
      setButtonMessage("Fill in an Email ...");
      setEmptyState(true);
      return false;
    }

    // Validate the email
    const isEmailValid = validateEmail(emailValue);
    if (!isEmailValid) {
      setHasBlurred(true);
      return false;
    }

    // If the email is valid, proceed with the submission
    setIsLoading(true);
    setButtonMessage("Submitting Request ... ");
    await delay(2000);
    console.log(userRole);
    try {
      let { data, error } = await supabase.auth.signInWithOtp({
        email: email, // Use the email state
        options: {
          emailRedirectTo: `${platformUrl}/profile`, // Adjust redirect URL
          data: {
            custom: {
              location: selectedLocation, // Use the selectedLocation state
              roles: userRole, // Use the selectedNavItem state
              organization: userOrganization?.route, // Use the organization state
            },
          },
        },
      });

      if (error) {
        console.error("Error signing in:", error);
        setIsLoading(false);
        setButtonMessage("Error with your Request");
        setIsSubmitted(false);
        return;
      }

      // Successful request
      // GA tracking
      trackEvent("user_registration_submitted", {
        method: "email_signup",
      });

      setIsLoading(false);
      setIsSubmitted(true);
      setButtonMessage("Check your email!");
    } catch (err) {
      console.error("Unexpected error occurred:", err);
      setIsLoading(false);
      setButtonMessage("Error with your Request");
    }
  };

  const handleRole = (event) => {
    const userRole = event.target.value;
    setUserRole(userRole);
    const selectedRole = NavItems.find((item) => item.role === userRole);
    window.history.pushState(null, "", ("/" + selectedRole.route)); // Update the browser URL
    console.log(userRole);
  };

  return (
    <>
      {" "}
      <Signup className="signup">
        <SignupContent $color={selectedNavItem?.primarycolor}>
          <Label $color={selectedNavItem?.primarycolor} $pulse={platformUser}>
            {selectedNavItem?.name}
          </Label>
          <Content>
            <Emoji>{selectedNavItem?.emoji}</Emoji>
            <Title>{selectedNavItem?.title}</Title>
          </Content>
          <Paragraph $color={selectedNavItem?.secondarycolor}>
            {selectedNavItem?.description}
            {selectedNavItem?.link && (
              <>
                <a href={selectedNavItem.link}>Learn more.</a>
              </>
            )}
          </Paragraph>
          <CounterLabel>Season 1 ends Mar 31, 2025!</CounterLabel>
          <Graphic>
            {/* {page === "home" ?  */}
            <Counter />
            {/* : null} */}
          </Graphic>

          <Form className="Form">
            <FormControl fullWidth>
              <SelectDropdown
                value={selectedLocation}
                onChange={handleLocationInput}
                labelId="location-select-label"
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    // Display the placeholder when no location is selected
                    return "🔎\u00A0 \u00A0Select your Nearest Location";
                  }
                  // Iterate over the provinces to find the matching city and its province's image
                  for (const [_, { name, image, cities }] of Object.entries(
                    provinces
                  )) {
                    const city = cities.find((c) => c.name === selected);
                    if (city) {
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* Assume image is a URL or a valid image path */}
                          {image} &nbsp;&nbsp;
                          {city.name}, {name}
                        </div>
                      );
                    }
                  }
                  return selected; // Fallback to just the name if not found (safety net)
                }}
              >
                {Object.entries(provinces).map(
                  ([value, { name, image, cities }]) => [
                    <ListSubheader key={value}>
                      {image} {name}
                    </ListSubheader>,
                    ...cities.map((city) => (
                      <MenuItem key={city.name} value={city.name}>
                        {city.name}
                      </MenuItem>
                    )),
                  ]
                )}
              </SelectDropdown>
            </FormControl>

            <FormControl>
              <SelectDropdown
                value={
                  userRole ? userRole : selectedNavItem?.role || "Select a Role"
                }
                labelId="role-select-label"
                displayEmpty
                onChange={handleRole}
              >
                {NavItems.map((navItem) => (
                  <MenuItem
                    key={navItem.role}
                    value={navItem.role}
                    disabled={navItem.role === "Select a Role"}
                  >
                    {navItem.emoji} &nbsp; {navItem.role}
                  </MenuItem>
                ))}
              </SelectDropdown>
            </FormControl>

            <EmailInput
              id="email-id"
              onChange={handleEmailInput}
              onBlur={handleBlur}
              placeholder="Email Address"
            />
          </Form>
          <Note $color={selectedNavItem?.secondarycolor}>
            <h4>👉🏽 When you Register</h4>
            You will receive an email to confirm your registration & create a profile. <b>We
              review all profiles to ensure a safe & secure community. </b><br />{" "}
            <br /> Approved profiles will move into program intake. &nbsp;
            <a href="/news"><b>Learn more.</b></a>
          </Note>
          {/* {selectedNavItem?.section2 ? (
            <RegistrationHero $color={selectedNavItem?.primarycolor}>
              <h3>
                Should you register as a {selectedNavItem.role}?
                </h3>
              <li>{selectedNavItem.section2.bullet1} </li>
              <li>{selectedNavItem.section2.bullet2} </li>
              <li>
                {selectedNavItem.section2.bullet3} <a href="">Learn more here.</a>
              </li>
            </RegistrationHero>
          ) : null} */}
        </SignupContent>
      </Signup>
      <SubmitContainer>
        <Submit
          onClick={handleRegistration}
          $color={selectedNavItem?.primarycolor}
          $color2={selectedNavItem?.secondarycolor}
          disabled={emailValid}
          $loading={isLoading}
        >
          {buttonMessage}
        </Submit>
      </SubmitContainer>
    </>
  );
}
